<template>
    <div style="margin-bottom: 5px">
        <el-select v-model="newAgent"
                   filterable
                   :placeholder="lang['html.select.placeholder']"
                   size="small"
                   style="margin-right: 5px"
        >
            <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.agentUserName"
                :value="item.agentUserName"
            />
        </el-select>

        <el-button @click="dialogVisible = true;inputUser=''" :disabled="isDisabled">{{lang['html.button.add']}}</el-button>
        <el-button @click="tableData = []" :disabled="isDisabled">{{lang['html.button.reset']}}</el-button>
        <el-popconfirm
            :title="lang['html.el-popconfirm.submit']"
            @confirm="submit"
        >
            <template #reference>
                <el-button :disabled="isDisabled">{{lang['html.button.submit']}}</el-button>
            </template>
        </el-popconfirm>

        <el-tag style="margin-left: 10px" type="info">
            {{lang['html.tag.userNum']}}: {{getUserNum}}
        </el-tag>
    </div>
    <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="username" label="UserName" width="200" />
        <el-table-column prop="state" label="State" />
    </el-table>

    <el-dialog v-model="dialogVisible" :title="lang['html.dialog.title']" width="30%">
        <el-input
            v-model="inputUser"
            type="textarea"
            :placeholder="lang['html.dialog.placeholder']"
            :rows="8"
        />
        <template #footer>
            <el-button @click="addUser(inputUser)">{{lang['html.dialog.footer.addUser']}}</el-button>
            <el-button @click="dialogVisible = false">{{lang['html.dialog.footer.return']}}</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {fromBase64} from "js-base64";

export default {
    name: "userMove",
    data(){
        return {
            tableData:[],
            userNameList:[],
            dialogVisible:false,//是否显示添加用户框
            inputUser:'',//用户输入的用户列表
            isDisabled:false,//是否禁止按钮
            agentList:[],//下级代理商列表
            newAgent:'',//要迁移的新代理商账号
            lang:this.$lang.getLang('user','userMove'),
        }
    },
    methods:{
        //添加用户
        addUser(userList){
            if (userList.length <= 0){
                userList = this.inputUser;
            }
            if (userList <= 0){
                this.$alert(this.lang['addUser.error.user'])
                return;
            }
            let arr = userList.split('\n');
            for (let arrKey in arr) {
                let user = arr[arrKey];
                this.tableData.push({
                    id:this.tableData.length + 1,
                    username:user,
                    state:'...'
                });
            }
            this.dialogVisible = false;
        },

        //提交请求
        submit(){
            let userList = this.getTableUserList();
            if(!userList){
                this.$alert(this.lang['submit.error.user']);
                return;
            }
            if (!this.newAgent){
                this.$alert(this.lang['submit.error.agent']);
                return;
            }
            this.isDisabled = true;
            this.$message(this.lang['submit.wait']);
            this.$api.post('User.UserMove/MoveUser',{
                userList:userList.toString(),
                newAgent:this.newAgent
            })
                .then(res=>{
                this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if (data){
                    //成功
                    this.setStateAll('√')
                    this.$alert(this.lang['submit.success'],{type:"success"});
                    return;
                }
                //调用Api失败
                let code = this.$helper.getResErrorCode(res);
                this.$helper.alertErrMsg(this.lang,'submit.res.errorCode.',code);
                this.setStateAll('×')
                })
                .catch(error=>{
                this.isDisabled = false;
                this.$helper.axiosCatch(error);
            })
        },

        /**
         * 批量设置表格状态
         */
        setStateAll(str){
            for (let key in this.tableData) {
                this.tableData[key].state = str
            }
        },

        /**
         * 取出要提交的用户名
         * @returns {null|*[]}
         * 成功返回数组 失败返回null
         */
        getTableUserList(){
            let arr = this.tableData;
            if (arr.length <= 0){
                return null;
            }
            let res = [];
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                res.push(temp['username']);
            }
            return res;
        },

        /**
         * 获取下级代理商列表
         */
        getAgentList(){
            this.$message('正在加载代理商列表...');
            this.$api.post('Agent.AgentData/getSubordinateAgent').then(res=>{
                let data = this.$helper.checkRes(res);
                if (!data){
                    this.$alert('加载代理商列表异常');
                    return;
                }
                let count = res.data.count ?? 0;
                if (Number(count) <= 0){
                    this.$alert('您没有下级代理商');
                }
                this.agentList = res.data.data;
            })
        }
    },
    computed:{
        getUserNum(){
            return this.tableData.length
        }
    },
    mounted() {
        this.isDisabled = true;
        //检查是否有传入用户列表参数
        let query = this.$route.params.userNameList;
        if (query){
            this.$notify({
                message:'正在导入用户...',
                type: 'info'
            });
            let str = fromBase64(query);
            let userList = str.replace(/,/g,"\n");
            this.addUser(userList);
        }
        //加载代理商列表
        this.getAgentList();
        this.isDisabled = false;
    }
}
</script>

<style scoped>

</style>